*{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -1;
}

.loading-bar
{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #ffffff;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.5s;
}

.loading-bar.ended
{
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform 1.5s ease-in-out;
}

.Titel
{   
    position: absolute;
    height: 100%;
    width: 100%;
    top: 25%;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    font-family: Helvetica, Arial, sans-serif;
    font-size: max(30px, 3vw);
    opacity: 1;
    transition: opacity 0.5s;
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */ 
}
.Untertitel
{   
    position: relative;
    top: 4%;
    color: #ffffff;
    font-size: 30px;
}

.Menu
{
    display: none;
    position:absolute;
    opacity: 0;
    transition: opacity 4.0s;
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */ 
}

.Menu .Logo
{
    color: #ffffff;
    white-space: nowrap;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 30px;
    padding: 20px;
}

.point
{
    position: absolute;
    top: 50%;
    left: 50%;
} 

.point .label
{
    position: absolute;
    top: -20px;
    left: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    line-height: 40px;
    font-weight: 100;
    font-size: 14px;
    cursor: help;
    transform: scale(0, 0);
    transition: transform 0.3s;
}

.point.visible .label
{
    transform: scale(1, 1);
}

.point .text
{
    position: absolute;
    top: 30px;
    left: -120px;
    width: 200px;
    padding: 20px;
    border-radius: 4px;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    line-height: 1.3em;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
}

.point:hover .text
{
    opacity: 1;
}

/* CHAT #################### */

* {box-sizing: border-box;}

/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
    display: none;
    background-color: rgb( 63, 171, 175);
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    bottom: 23px;
    right: 28px;
    width: 280px;
    max-width: 85%;
}

.close-button {
    display: none;
    background-color: rgb( 253,  88,  37);
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.9;
    position: fixed;
    bottom: 23px;
    right: 28px;
    width: 280px;
    max-width: 85%;
}

/*
/* The popup chat - hidden by default 
.chat-popup {
    display: none;
    position: fixed;
    bottom: 0;
    right: 15px;

    /* border: 3px solid #c8c8c8; 
    z-index: 9;
    font-family: Helvetica, Arial, sans-serif;
    color: #ffffff;
  }

  /* Add styles to the form container 
.form-container {
    max-width: 300px;
    padding: 10px;
    background-color: rgb(42, 42, 42);
  }

  /* Full-width textarea 
.form-container textarea {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;

    /* background: #f1f1f1; 
    background: #c8c8c8;
    resize: none;
    min-height: 200px;
  }

  /* When the textarea gets focus, do something 
.form-container textarea:focus {
    background-color: #ddd;
    outline: none;
  }
  
  /* Set a style for the submit/login button 
  .form-container .btn {
    background-color: rgb( 63, 171, 175);
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom:10px;
    opacity: 1.0;
  }

  /* Add a red background color to the cancel button 
.form-container .cancel {
    background-color: rgb(253,  88,  37);
  }
  
  /* Add some hover effects to buttons 
  .form-container .btn:hover, .open-button:hover {
    opacity: 0.5;
  }
*/
  .myFrame {
    display: none;
    position: fixed;
    bottom: 73px;
    right: 28px;
    width: 600px;
    min-width: 35%;
    max-width: 80%;
    height: 80%; 
  }

  #heatMap{
    display: none;
    position: absolute;
    top: 80px;
    left: 20px;
    border: 1px solid #F7EFE5;
  }